import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './componates/navbar/Navbar';
import Shop from './Shop';
import Prefabrique from './componates/prefabrique/Prefabrique';
import Merci from './componates/merci/Merci';
import Login from './componates/login/Login';
import About from './componates/abdout/About';
import Product from './componates/product/Product';
import Projectform from './componates/projectform/Projectform';
import Admin from './componates/admin/Admin';

import { auth, db } from './componates/login/firebaseConfig'; // Firebase config
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

function App(props) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [password, setPassword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);

  const correctPassword = "motdepasse123"; // Remplacez par votre mot de passe

  useEffect(() => {
    // Observer l'état de l'utilisateur
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsAdmin(userData.role === 'admin');
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setIsAuthorized(true);
    } else {
      alert("Mot de passe incorrect !");
    }
  };

  if (loading) {
    return <p>Chargement...</p>; // Affichage pendant le chargement
  }

  if (!isAuthorized) {
    return (
      <div className="password-protection">
        <h2>Entrez le mot de passe pour accéder au site :</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
        />
        <button onClick={handlePasswordSubmit}>Soumettre</button>
      </div>
    );
  }

  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Shop />} />
          <Route path="/Préfabriqué" element={<Prefabrique />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Product />} />
          <Route path="/projectform" element={<Projectform />} />
          <Route path="/merci" element={<Merci />} />
          <Route path="/login" element={<Login />} />
          <Route 
            path="/admin" 
            element={isAdmin ? <Admin /> : <Navigate to="/login" />} 
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
