import React, { useState } from 'react';
import axios from 'axios';
import './addproductadmin.css'; // CSS spécifique au composant

function AddProductAdmin() {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [dimensions, setDimensions] = useState({ length: '', width: '', height: '' });
  const [weight, setWeight] = useState('');
  const [material, setMaterial] = useState('');
  const [price, setPrice] = useState('');
  const [availability, setAvailability] = useState(true);
  const [customizable, setCustomizable] = useState(false);
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('category', category);
    formData.append('description', description);
    formData.append('dimensions', JSON.stringify(dimensions)); // Transforme en JSON pour envoyer au back-end
    formData.append('weight', weight);
    formData.append('material', material);
    formData.append('price', price);
    formData.append('availability', availability);
    formData.append('customizable', customizable);
    if (image) {
      formData.append('image', image); // Ajout du fichier image
    }

    try {
      const response = await axios.post('https://simple-project-1.onrender.com/addproduct', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        },
      });

      setMessage("Le produit a été ajouté avec succès !");
      setName('');
      setCategory('');
      setDescription('');
      setDimensions({ length: '', width: '', height: '' });
      setWeight('');
      setMaterial('');
      setPrice('');
      setAvailability(true);
      setCustomizable(false);
      setImage(null);

    } catch (error) {
      setMessage("Erreur lors de l'ajout du produit.");
      console.error(error);
    }
  };

  return (
    <div className="add-product-admin">
      <h2>Ajouter un produit</h2>
      <form onSubmit={handleSubmit} className="add-product-form">
        <label className="form-label">
          Nom du produit :
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-input" required />
        </label>
        <label className="form-label">
          Catégorie :
          <select value={category} onChange={(e) => setCategory(e.target.value)} className="form-select" required>
            <option value="">Sélectionner une catégorie</option>
            <option value="Murs">Murs</option>
            <option value="Dalles">Dalles</option>
            <option value="Poutres">Poutres</option>
            <option value="Colonnes">Colonnes</option>
            <option value="Autres">Autres</option>
          </select>
        </label>
        <label className="form-label">
          Description :
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="form-textarea" required />
        </label>
        <label className="form-label">
          Dimensions (en cm) :
          <input
            type="number"
            placeholder="Longueur"
            value={dimensions.length}
            onChange={(e) => setDimensions({ ...dimensions, length: e.target.value })}
            className="form-input"
            required
          />
          <input
            type="number"
            placeholder="Largeur"
            value={dimensions.width}
            onChange={(e) => setDimensions({ ...dimensions, width: e.target.value })}
            className="form-input"
            required
          />
          <input
            type="number"
            placeholder="Hauteur"
            value={dimensions.height}
            onChange={(e) => setDimensions({ ...dimensions, height: e.target.value })}
            className="form-input"
            required
          />
        </label>
        <label className="form-label">
          Poids (en kg) :
          <input type="number" value={weight} onChange={(e) => setWeight(e.target.value)} className="form-input" required />
        </label>
        <label className="form-label">
          Matériau :
          <select value={material} onChange={(e) => setMaterial(e.target.value)} className="form-select" required>
            <option value="">Sélectionner un matériau</option>
            <option value="Béton armé">Béton armé</option>
            <option value="Béton précontraint">Béton précontraint</option>
            <option value="Autre">Autre</option>
          </select>
        </label>
        <label className="form-label">
          Prix :
          <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} className="form-input" required />
        </label>
        <label className="form-label">
          Disponible :
          <input
            type="checkbox"
            checked={availability}
            onChange={(e) => setAvailability(e.target.checked)}
            className="form-checkbox"
          />
        </label>
        <label className="form-label">
          Personnalisable :
          <input
            type="checkbox"
            checked={customizable}
            onChange={(e) => setCustomizable(e.target.checked)}
            className="form-checkbox"
          />
        </label>
        <label className="form-label">
          Image :
          <input type="file" onChange={handleImageChange} accept="image/*" className="form-file" required />
        </label>
        <button type="submit" className="submit-btn">Ajouter le produit</button>
      </form>
      {message && <p className="response-message">{message}</p>}
    </div>
  );
}

export default AddProductAdmin;
