import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './allarticule.css';
import remove_icon from '../../assets/trash.png';

function Allarticule({ refresh }) {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState('');

  const fetchArticles = async () => {
    try {
      const response = await axios.get('https://back-end-kappa-seven.vercel.app/articles');
      setArticles(response.data);
    } catch (err) {
      setError("Erreur lors de la récupération des articles.");
      console.error(err);
    }
  };

  const removeArticle = async (id) => {
    try {
      await fetch('https://simple-project-1.onrender.com/removearticle', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });
      setArticles(articles.filter((article) => article._id !== id));
      alert("Article supprimé avec succès !");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'article :", error);
    }
  };

  // Charger les articles au montage et après chaque changement de `refresh`
  useEffect(() => {
    fetchArticles();
  }, [refresh]);

  return (
    <div className="all-articule">
      <h2>Tous les articles</h2>
      {error && <p className="error">{error}</p>}
      <div className="articles-list">
        {articles.map((article) => (
          <div key={article._id} className="article-item">
            <h3>{article.title}</h3>
            <p><strong>Domaine :</strong> {article.domain}</p>
            {article.image && (
              <img
                src={`https://back-end-kappa-seven.vercel.app${article.image}`}
                alt={article.title}
              />
            )}
            <p>Contenu de l'article : {article.content}</p>
            <img
              onClick={() => removeArticle(article._id)}
              className="listproduct-remove-icon"
              src={remove_icon}
              alt="Supprimer"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Allarticule;
