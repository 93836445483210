import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './allproductadmin.css';  // Ajoute un CSS spécifique à ce composant
import remove_icon from '../../../assets/trash.png';  // Icône de suppression

function AllProductAdmin({ refresh }) {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState('');

  // Fonction pour récupérer les produits
  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://back-end-kappa-seven.vercel.app/products'); // Assurez-vous que votre backend retourne la liste des produits
      setProducts(response.data);
    } catch (err) {
      setError("Erreur lors de la récupération des produits.");
      console.error(err);
    }
  };

  // Fonction pour supprimer un produit
  const removeProduct = async (id) => {
    try {
      await axios.post('https://simple-project-1.onrender.com/removeproduct', { id }); // Requête pour supprimer le produit
      setProducts(products.filter((product) => product._id !== id));  // Filtrer le produit supprimé de l'état
      alert("Produit supprimé avec succès !");
    } catch (error) {
      console.error("Erreur lors de la suppression du produit :", error);
    }
  };

  // Charger les produits au montage et à chaque changement de `refresh`
  useEffect(() => {
    fetchProducts();
  }, [refresh]);

  return (
    <div className="all-product-admin">
      <h2>Tous les produits</h2>
      {error && <p className="error">{error}</p>}
      <div className="product-list">
        {products.map((product) => (
          <div key={product._id} className="product-item">
            <h3>{product.name}</h3>
            <p><strong>Catégorie :</strong> {product.category}</p>
            <p><strong>Description :</strong> {product.description}</p>
            <p><strong>Poids :</strong> {product.weight} kg</p>
            <p><strong>Prix :</strong> {product.price} </p>
            {product.image && (
              <img
                src={`https://back-end-kappa-seven.vercel.app${product.image}`}  // Assurez-vous que le backend renvoie l'URL de l'image
                alt={product.name}
                className="product-image"
              />
            )}
            <div className="product-actions">
              <img
                onClick={() => removeProduct(product._id)}
                className="remove-icon"
                src={remove_icon}
                alt="Supprimer"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AllProductAdmin;
