import React from 'react';
import Accueil from './componates/accueil/Accueil';
import Affiche from './componates/affiche/Affiche';
import './shop.css';
import Questions from './componates/questions/Questions';
import Contcat from './componates/contact/Contact';

function Shop() {
    return (
        <div>
            <Accueil />
            <h1 className='mid'>Nos meilleurs produits</h1>
            <Affiche />
            <Questions />
            <Contcat />
        </div>
    );
}

export default Shop;
